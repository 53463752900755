import modals from '../../support/modals'
import { trackInternalEvent } from '@rio/tracking'

class CookiePanel {
  constructor($panel, cookieController) {
    this.$panel = $panel
    this.cookieController = cookieController
    this.$acceptButton = this.$panel.find('.cookie-panel__accept')
    this.$rejectButton = this.$panel.find('.cookie-panel__reject')
    this.$settingsButton = this.$panel.find('.cookie-panel__settings')

    this.open()
    this.$acceptButton.click(this.handleAcceptAllCookies)
    this.$rejectButton.click(this.handleRejectAllCookies)
    this.$settingsButton.click(this.handleSettingsClick)
  }

  handleAcceptAllCookies = () => {
    if (this.cookieController) {
      this.cookieController.acceptAllCookies()
    }

    trackInternalEvent('click_cookie_banner', {
      cta_text: 'Accept all',
      cookie_settings: 'Accept all',
    })

    this.close()
  }

  handleRejectAllCookies = () => {
    if (this.cookieController) {
      this.cookieController.rejectAllCookies()
    }

    trackInternalEvent('click_cookie_banner', {
      cta_text: 'Reject all',
      cookie_settings: 'Reject',
    })

    this.close()
  }

  handleSettingsClick = () => {
    trackInternalEvent('open_cookie_banner_settings')

    modals.cookieSettingsModal.open()
  }

  open = () => {
    this.$panel.addClass('is-open')
  }

  close = () => {
    this.$panel.removeClass('is-open')
  }
}

export default CookiePanel
