import { trackInternalEvent } from '@rio/tracking'

const bannerInteractionEvent = ({ ctaText, ctaUrl }) => {
  trackInternalEvent('click_website_banner', {
    url: ctaUrl,
    label: ctaText || '',
  })
}

class ModalCarousel {
  constructor() {
    this.modal = document.getElementById('popupModal')
    if (!this.modal) {
      console.error('Modal element not found!')
      return
    }
    this.slides = this.modal.querySelectorAll('.slide')
    this.progressDots = this.modal.querySelectorAll('.dot')
    this.currentIndex = 0

    this.initializeButtons()
    this.initializeKeyboardControls()
    this.updateSlides()

    if (this.shouldShowModal()) {
      this.open()
      localStorage.setItem('modalShown', 'true')
    }
  }

  initializeButtons() {
    this.modal.querySelector('.modal-close').addEventListener('click', () => this.close())

    this.modal.querySelectorAll('.skip-button').forEach(button => {
      button.addEventListener('click', () => this.nextSlide())
    })

    this.modal.querySelectorAll('.next-button').forEach(button => {
      button.addEventListener('click', () => this.nextSlide())
    })

    this.modal.querySelectorAll('.back-button').forEach(button => {
      button.addEventListener('click', () => this.previousSlide())
    })

    this.modal.querySelectorAll('.cta-button').forEach(button => {
      button.addEventListener('click', () => {
        const currentSlide = this.slides[this.currentIndex]
        bannerInteractionEvent({
          ctaText: currentSlide.dataset.ctaText,
          ctaUrl: currentSlide.dataset.url || '',
        })
      })
    })
  }

  initializeKeyboardControls() {
    document.addEventListener('keydown', e => {
      if (!this.modal.classList.contains('active')) return

      switch (e.key) {
        case 'Escape':
          this.close()
          break
        case 'ArrowRight':
          this.nextSlide()
          break
        case 'ArrowLeft':
          this.previousSlide()
          break
      }
    })
  }

  nextSlide() {
    bannerInteractionEvent({ ctaText: 'Next' })
    if (this.currentIndex < this.slides.length - 1) {
      this.currentIndex++
      this.updateSlides()
    } else {
      this.close()
    }
  }

  previousSlide() {
    bannerInteractionEvent({ ctaText: 'Back' })
    if (this.currentIndex > 0) {
      this.currentIndex--
      this.updateSlides()
    }
  }

  updateSlides() {
    this.slides.forEach((slide, index) => {
      slide.classList.toggle('active', index === this.currentIndex)
      slide.style.transform = `translateX(${(index - this.currentIndex) * 100}%)`
    })

    this.progressDots.forEach((dot, index) => {
      dot.classList.toggle('active', index === this.currentIndex)
    })
  }

  open() {
    this.modal.classList.add('active')
  }

  close() {
    this.modal.classList.remove('active')
    bannerInteractionEvent({ ctaText: 'Close' })
  }

  shouldShowModal() {
    return !localStorage.getItem('modalShown')
  }
}

new ModalCarousel()
