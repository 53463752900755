const TRACK_EVENT_TIMEOUT = process.env.NODE_ENV === 'development' ? 200 : 5000

interface Options {
  eventLabel?: string | number
  documentType?: string
}

// Declare `dataLayer` array in window
declare global {
  interface Window {
    dataLayer?: Array<unknown>
  }
}

/** Constructs a callback function with timeout */
const callbackWithTimeout = (callback: () => void, timeout: number) => {
  let timeoutFired = false

  const timer = setTimeout(() => {
    timeoutFired = true
    callback()
  }, timeout)

  return () => {
    if (timeoutFired) return
    clearTimeout(timer)
    callback()
  }
}

/**
 * Send event to Google Analytics.
 */
export const trackMarketingEvent = (
  eventCategory: string,
  eventAction: string,
  options: Options = {},
) => {
  return new Promise<void>(resolve => {
    // the following lines ensure that the promise is resolved
    // even if the analytics engine will never response
    const eventCallback = callbackWithTimeout(() => resolve(), TRACK_EVENT_TIMEOUT)

    // Create GTM event
    const gtmEvent = {
      event: 'gtmAppEvent',
      eventCategory,
      eventAction,
      eventCallback,
      ...options,
    }

    // pass the event to Google Tag Manager
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(gtmEvent)

    // log the event to the console in development
    if (process.env.NODE_ENV === 'development') {
      console.log(`Marketing Tracking: Send "${eventCategory}: ${eventAction}"`, options)
    }
  })
}

/**
 * Helper to simplify `trackMarketingEvent` usage in JSX.
 *
 * @example
 * <div onClick={createMarketingEventHandler("Dashboard", "Download Resume")}>
 */
export const createMarketingEventHandler = (...args: Parameters<typeof trackMarketingEvent>) => {
  return () => trackMarketingEvent(...args)
}
