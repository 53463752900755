import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'
import { Swiper, Lazy, Navigation, Scrollbar } from 'swiper'

Swiper.use([Lazy, Navigation, Scrollbar])

class BlogFeaturedPostComponent {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    new Swiper(this.$el.get(0), {
      loop: this.$el.find('.home-blog__slider-item').length > 3,
      threshold: 15,
      centeredSlides: true,
      spaceBetween: 0,
      navigation: {
        prevEl: '.home-blog__nav--prev',
        nextEl: '.home-blog__nav--next',
      },
      slideClass: 'home-blog__slider-item',
      wrapperClass: 'home-blog__slider-inner',
      breakpoints: {
        1150: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 2.5,
        },
        768: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1: {
          slidesPerView: 1,
        },
      },
    })
  }
}

ready(function () {
  $('.home-blog__slider-container').each((_, node) => {
    new BlogFeaturedPostComponent(node)
  })
})
