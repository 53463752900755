import { Optional } from 'packages/types'
import { isIFrame } from 'builder/utils/isIFrame'
import events from '../events.json'

/**
 * Type guard preventing invalid event names usage.
 * Open `/packages/tracking/events.json` to check for all allowed names or add a new one.
 */
export type EventCode = keyof typeof events.public | keyof typeof events.private

/** Only primitive values are supported by ClickHouse */
type Primitive = string | number | boolean | null
type Payload = { label?: string } & Record<string, Optional<Primitive | Primitive[]>>

/** Fetches the browser window and screen size */
const getResolutions = (): Payload => ({
  window_width: window.innerWidth,
  window_height: window.innerHeight,
  screen_width: window.screen.width,
  screen_height: window.screen.height,
})

/** Fetches the referer URL and its query params */
const getReferer = (): Payload => {
  const referer = document.referrer
  const priorReferer = referer || 'direct'

  if (priorReferer === 'direct') {
    return {
      prior_referer: priorReferer,
    }
  } else {
    const refererUrl = new URLSearchParams(referer)
    const refererParams = Object.fromEntries(refererUrl)

    // add ref_ prefix to all params from referer
    if (Object.keys(refererParams).length === 0) {
      return {
        prior_referer: priorReferer,
      }
    } else {
      for (const param in refererParams) {
        refererParams[`ref_${param}`] = refererParams.param
        delete refererParams.param
      }
      return {
        prior_referer: priorReferer,
        ...refererParams,
      }
    }
  }
}

/** Fetches the URL query params */
const parseParams = (): Payload => {
  const params = new URLSearchParams(window.location.search)

  return Object.fromEntries(params)
}

const shouldShareEventFromWidgetMode = (event: EventCode): boolean => {
  return [
    'create_resume',
    'download_resume',
    'change_resume_template',
    'rename_resume',
    'open_full_screen_resume_preview',
    'visit_resume_editor',
    'click_download_resume_button',
    'click_edit_resume_button',
    'open_sharing_modal',
    'visit_dashboard',
  ].includes(event)
}

const isEventUsedPubliclyAndPrivately = (event: EventCode): boolean => {
  return ['track_utm'].includes(event)
}

/**
 * Sends the event to the internal event tracking system (ClickHouse).
 */
export const trackInternalEvent = (eventCode: EventCode, payload: Payload = {}) => {
  if (typeof eventCode !== 'string') {
    return console.error('🛑 ClickHouse Tracking: An event code must be provided')
  }

  // timestamp in seconds since 1970 for attach to params
  const timestamp = Math.round(Date.now() / 1000)
  const timestampMs = Date.now()

  // attach screen and browser sizes to improve data analysis quality
  const params: Payload = {
    ...payload,
    ...getResolutions(),
    ...getReferer(),
    ...parseParams(),
    timestamp,
    timestamp_ms: timestampMs,
  }

  // call the endpoint via `sendBeacon` so it won't be aborted if a user leaves a page
  if ('navigator' in window && typeof navigator.sendBeacon === 'function') {
    try {
      navigator.sendBeacon(`/api/reporting/event/${eventCode}`, JSON.stringify({ params }))
    } catch (error) {
      // TODO: move error logger to package and use it here
      console.error(error)
    }
  }

  // dispatch event for parent frame, used when resume rendered
  // as a widget to send events to partners frontend
  if (isIFrame() && shouldShareEventFromWidgetMode(eventCode)) {
    try {
      const eventDetails = { eventCode: eventCode, payload: payload }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.top.postMessage(eventDetails, '*')
    } catch (error) {
      console.error('🛑 Parent event: error dispatching to parent window', error)
    }
  }

  // Improve DX by notifying about events tracking (including invalid ones)
  if (process.env.NODE_ENV === 'development') {
    if (eventCode in events.public === false && !isEventUsedPubliclyAndPrivately(eventCode)) {
      console.error(`🛑 ClickHouse Tracking: "${eventCode}" must be present in events.json`)
    } else {
      console.info(`ClickHouse Tracking: Send "${eventCode}"`, params)
    }
  }
}

/**
 * Helper to simplify `trackInternalEvent` usage in JSX.
 *
 * @example
 * <div onClick={createInternalEventHandler("download_resume")}>
 */
export const createInternalEventHandler = (...args: Parameters<typeof trackInternalEvent>) => {
  return () => trackInternalEvent(...args)
}
